import { Action } from '@ngrx/store';
import { IUserModel } from '@app/core/models/user.model';
import { IRegisterAccountRequestModel } from '@models/request/register-account.request.model';
import { IPreAuthModel } from '@models/pre-auth.model';
import { IAccountModel } from '@models/account.model';
import { PaginationHolder } from '@models/pagination.model';
import { ICreateUserRequestModel } from '@models/request/create-user.request.model';
import { IUpdateAccountRequestModel } from '@models/request/update-account.request.model';
import { IAccountSettingsModel } from '@models/account-settings.model';

export enum EAuthenticationActions {
  CreateAccount = '[ACCOUNT] Create Account',
  CreateAccountSuccess = '[ACCOUNT] Create Account Success',

  PreAuth = '[ACCOUNT] Pre Auth',
  PreAuthSuccess = '[ACCOUNT] Pre Auth Success',

  Login = '[ACCOUNT] Login',
  LoginSuccess = '[ACCOUNT] Login Success',

  LoginWithToken = '[ACCOUNT] Login With Token',
  LoginWithTokenSuccess = '[ACCOUNT] Login With Token Success',

  ForgotPassword = '[ACCOUNT] Forgot Password',
  ForgotPasswordSuccess = '[ACCOUNT] Forgot Password Success',

  ResetPassword = '[ACCOUNT] Reset Password',
  ResetPasswordSuccess = '[ACCOUNT] Reset Password Success',

  FetchUsers = '[ACCOUNT] Fetch Users',
  FetchUsersSuccess = '[ACCOUNT] Fetch Users Success',

  CreateUser = '[ACCOUNT] Create User',
  CreateUserSuccess = '[ACCOUNT] Create User Success',

  UpdateUser = '[ACCOUNT] Update User',
  UpdateUserSuccess = '[ACCOUNT] Update User Success',

  UpdateUserWithImage = '[ACCOUNT] Update User With Image',
  UpdateUserWithImageSuccess = '[ACCOUNT] Update User With Image Success',

  FetchAccount = '[ACCOUNT] Fetch Account',
  FetchAccountSuccess = '[ACCOUNT] Fetch Account Success',

  UpdateAccount = '[ACCOUNT] Update Account',
  UpdateAccountSuccess = '[ACCOUNT] Update Account Success',

  FetchAccountSettings = '[ACCOUNT] Fetch Account Settings',
  FetchAccountSettingsSuccess = '[ACCOUNT] Fetch Account Settings Success',

  UpdateAccountSettings = '[ACCOUNT] Update Account Settings',
  UpdateAccountSettingsSuccess = '[ACCOUNT] Update Account Settings Success',

  UpdateOnboardingStatus = '[ACCOUNT] Update Onboarding Status',

  SwitchAccount = '[ACCOUNT] Switch Account',
  SwitchAccountSuccess = '[ACCOUNT] Switch Account Success',

  Logout = '[ACCOUNT] Log out',
}

// Create Account

export class CreateAccount implements Action {
  public readonly type = EAuthenticationActions.CreateAccount;

  constructor(
    public reqModel: IRegisterAccountRequestModel,
    public captcha_token: string
  ) {}
}

export class CreateAccountSuccess implements Action {
  public readonly type = EAuthenticationActions.CreateAccountSuccess;

  constructor(public user: IUserModel) {}
}

// Login

export class PreAuth implements Action {
  public readonly type = EAuthenticationActions.PreAuth;

  constructor(
    public email: string,
    public password: string,
    public captcha_token: string
  ) {}
}

export class PreAuthSuccess implements Action {
  public readonly type = EAuthenticationActions.PreAuthSuccess;

  constructor(public accounts: IPreAuthModel[]) {}
}

// Login

export class Login implements Action {
  public readonly type = EAuthenticationActions.Login;

  constructor(
    public email: string,
    public password: string,
    public account: IPreAuthModel
  ) {}
}

export class LoginSuccess implements Action {
  public readonly type = EAuthenticationActions.LoginSuccess;

  constructor(public account: IAccountModel) {}
}

// Login With Token

export class LoginWithToken implements Action {
  public readonly type = EAuthenticationActions.LoginWithToken;

  constructor(
    public token: string,
    public user_id: string,
    public account_id: string
  ) {}
}

export class LoginWithTokenSuccess implements Action {
  public readonly type = EAuthenticationActions.LoginWithTokenSuccess;

  constructor(public account: IAccountModel) {}
}

// Forgot Password

export class ForgotPassword implements Action {
  public readonly type = EAuthenticationActions.ForgotPassword;

  constructor(public email: string) {}
}

export class ForgotPasswordSuccess implements Action {
  public readonly type = EAuthenticationActions.ForgotPasswordSuccess;

  constructor() {}
}

// Reset Password

export class ResetPassword implements Action {
  public readonly type = EAuthenticationActions.ResetPassword;

  constructor(
    public password: string,
    public token: string
  ) {}
}

export class ResetPasswordSuccess implements Action {
  public readonly type = EAuthenticationActions.ResetPasswordSuccess;

  constructor() {}
}

// Fetch Users

export class FetchUsers implements Action {
  public readonly type = EAuthenticationActions.FetchUsers;

  constructor() {}
}

export class FetchUsersSuccess implements Action {
  public readonly type = EAuthenticationActions.FetchUsersSuccess;

  constructor(public users: PaginationHolder<IUserModel>) {}
}

// Create Users

export class CreateUser implements Action {
  public readonly type = EAuthenticationActions.CreateUser;

  constructor(public reqModel: ICreateUserRequestModel) {}
}

export class CreateUserSuccess implements Action {
  public readonly type = EAuthenticationActions.CreateUserSuccess;

  constructor() {}
}

// Update User

export class UpdateUser implements Action {
  public readonly type = EAuthenticationActions.UpdateUser;

  constructor(
    public id: string,
    public reqModel: ICreateUserRequestModel
  ) {}
}

export class UpdateUserSuccess implements Action {
  public readonly type = EAuthenticationActions.UpdateUserSuccess;

  constructor() {}
}

// Update User With Image

export class UpdateUserWithImage implements Action {
  public readonly type = EAuthenticationActions.UpdateUserWithImage;

  constructor(
    public id: string,
    public reqModel: ICreateUserRequestModel,
    public file: File
  ) {}
}

export class UpdateUserWithImageSuccess implements Action {
  public readonly type = EAuthenticationActions.UpdateUserWithImageSuccess;

  constructor() {}
}

// Fetch Account

export class FetchAccount implements Action {
  public readonly type = EAuthenticationActions.FetchAccount;

  constructor() {}
}

export class FetchAccountSuccess implements Action {
  public readonly type = EAuthenticationActions.FetchAccountSuccess;

  constructor(public account: IAccountModel) {}
}

// Fetch Account Settings

export class FetchAccountSettings implements Action {
  public readonly type = EAuthenticationActions.FetchAccountSettings;

  constructor() {}
}

export class FetchAccountSettingsSuccess implements Action {
  public readonly type = EAuthenticationActions.FetchAccountSettingsSuccess;

  constructor(public settings: IAccountSettingsModel) {}
}

// Update Account Settings

export class UpdateAccountSettings implements Action {
  public readonly type = EAuthenticationActions.UpdateAccountSettings;

  constructor(public reqData: IAccountSettingsModel) {}
}

export class UpdateAccountSettingsSuccess implements Action {
  public readonly type = EAuthenticationActions.UpdateAccountSettingsSuccess;

  constructor(public settings: IAccountSettingsModel) {}
}

// Update Account

export class UpdateAccount implements Action {
  public readonly type = EAuthenticationActions.UpdateAccount;

  constructor(public reqModel: IUpdateAccountRequestModel) {}
}

export class UpdateAccountSuccess implements Action {
  public readonly type = EAuthenticationActions.UpdateAccountSuccess;

  constructor() {}
}

// Update Onboarding Status

export class UpdateOnboardingStatus implements Action {
  public readonly type = EAuthenticationActions.UpdateOnboardingStatus;

  constructor(
    public settings?: boolean,
    public link?: boolean,
    public completed?: boolean
  ) {}
}

// Switch Account

export class SwitchAccount implements Action {
  public readonly type = EAuthenticationActions.SwitchAccount;

  constructor(public account: IPreAuthModel) {}
}

export class SwitchAccountSuccess implements Action {
  public readonly type = EAuthenticationActions.SwitchAccountSuccess;

  constructor() {}
}

// Log out

export class Logout implements Action {
  public readonly type = EAuthenticationActions.Logout;

  constructor() {}
}

// Types

export type AuthenticationActions =
  | CreateAccount
  | CreateAccountSuccess
  | PreAuth
  | PreAuthSuccess
  | Login
  | LoginSuccess
  | LoginWithToken
  | LoginWithTokenSuccess
  | ForgotPassword
  | ForgotPasswordSuccess
  | ResetPassword
  | ResetPasswordSuccess
  | FetchUsers
  | FetchUsersSuccess
  | CreateUser
  | CreateUserSuccess
  | UpdateUser
  | UpdateUserSuccess
  | UpdateUserWithImage
  | UpdateUserWithImageSuccess
  | FetchAccount
  | FetchAccountSuccess
  | UpdateAccount
  | UpdateAccountSuccess
  | FetchAccountSettings
  | FetchAccountSettingsSuccess
  | UpdateAccountSettings
  | UpdateAccountSettingsSuccess
  | SwitchAccount
  | SwitchAccountSuccess
  | UpdateOnboardingStatus;
